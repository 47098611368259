import React from 'react'
import Data from './../components/Data'
import { useParams } from 'react-router'

const DataPage = () => {
    const {clase, objectId } = useParams();
    return (
        <div>
            <Data clase={clase} objectId={objectId}/>
        </div>
    )
}

export default DataPage
