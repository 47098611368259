import { Fragment, useState, useEffect } from 'react'
import Parse from 'parse'
import Return from './../components/Return'

const Data = ({ objectId, clase }) => {

    const [resultsData, setResultsData] = useState(null);

    useEffect(() => {
        const Cuenta = Parse.Object.extend(clase);
        const query = new Parse.Query(Cuenta);

        query.equalTo("objectId", objectId);
        query.include(['paciente', 'ingresoPaciente', 'autor']);
        query.find().then((results) => {
            const [resultsData] = JSON.parse(JSON.stringify(results));
            setResultsData(resultsData);
            console.log(resultsData);
        }).catch((error) => {
            console.log(error);
        });
    }, [clase, objectId]);


    if (!resultsData) return (
        <div className='container'>
            <h2>No se encontró información</h2>
            <Return />
        </div>
    )

    const { lastName1, lastName2, names } = resultsData.paciente || {}

    return (
        <div className='container'>
            <div className="sub">
                Documento Válido
            </div>
            <Fragment>
                <div className="label">
                    Fecha
                <div className="data">
                        {new Date(resultsData.createdAt).toLocaleString("es-ES")}
                    </div>
                </div>
                <div className="label">
                    Folio
                <div className="data">
                        {resultsData.cuenta.folio}
                    </div>
                </div>
                <div className="label">
                    Paciente
                <div className="data">
                        {`${lastName1} ${lastName2} ${names}`}
                    </div>
                </div>
                <div className="label">
                    Cantidad
                <div className="data">
                        {`$${resultsData.cuenta.abono.toFixed(2)} MXN`}
                    </div>
                </div>
                <div className="label">
                    Tipo de Pago
                <div className="data">
                        {resultsData.tipoPago}
                    </div>
                </div>
            </Fragment>
            <Return />
        </div>
    )
}

export default Data;