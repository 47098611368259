import React from 'react'
import { useHistory } from 'react-router'

const Return = () => {
    const history = useHistory();

    const onClickHandler = () => {
        history.push('/')
    }
    return (
        <div>
            <button onClick={onClickHandler} className='button'>
                Regresar
            </button>
        </div>
    )
}

export default Return
