import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Parse from 'parse'
import * as Env from './enviroment'
import { BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom'
import DataPage from './pages/DataPage';

Parse.initialize(Env.APPLICATION_ID, Env.JAVASCRIPT_KEY)
Parse.serverURL = Env.SERVER_URL
//uh9qRi6tow
function App() {

    return (
        <div className='App'>
            <div className='App-header'>
                <a
                    href="https://hospitalrealsanlucas.com.mx/"
                    title="Hospital Real San Lucas"
                    width="300"
                >
                    <img
                        src="https://i2.wp.com/hospitalrealsanlucas.com.mx/wp-content/uploads/2019/03/cropped-Logotipo-01-1-scaled.png?fit=2560%2C640&ssl=1"
                        alt="Hospital Real San Lucas"
                        width="500"
                        height="100"
                    />
                </a>
            </div>
            <div className='sub'>
                <span> Módulo de Verificación </span>
            </div>
            <Router basename="verificar.hospitalrealsanlucas.com.mx/">
                <Switch>
                    <Route path="/:clase/:objectId">
                        <DataPage />
                    </Route>
                    <Route>
                        <h2>Not Found</h2>
                    </Route>
                    <Redirect to="/" />
                </Switch>

            </Router>

        </div>
    );
}

export default App;
